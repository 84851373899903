import { useEffect, useState } from "react";
import Select from "react-select";
import { ChartContainer } from "../../components/Chart";
import { filtersDefaultChart } from "../../utils/constant";
import { useDialog } from "../../contexts/DialogContext";
import { requestAuth } from "../../components/services/RequestService";
import { SimpleTableManagement } from "../../containers/Systems/TableManagement";
import { Circle } from "../../components/Animation";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ExportProcedureExcel } from "../../containers/steps/ExportProcedureExcel";
import { Button } from "../../components/Buttons";

const Management = () => {
  return <ManagementContainer />;
};

const ManagementContainer = () => {
  const [year, setYear] = useState("2024");
  const [chartType, setChartType] = useState("Clientes");
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const location = useLocation();

  const graficas = [
    { value: "Clientes", label: "Clientes" },
    { value: "Canales", label: "Canales" },
    // { value: "Mercadería", label: "Mercadería" },
    { value: "Aduanas De Despachos", label: "Aduanas De Despachos" },
    { value: "Liquidadores", label: "Liquidadores" },
    // { value: "Gestores", label: "Gestores" },
  ];
  const gestiones = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const handleChartTypeChange = (selectedOption) => {
    setChartType(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption.value);
  };

  const ContentData = ({ actualData, selectedData }) => {
    let urlApi = `${actualData.urlApiMain}&value=${actualData.fieldValue}&company=${selectedData.companyId}&month=${actualData.numMonth}&demo=emo`;

    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false);
    useEffect(() => {
      setLoad(true);
      requestAuth("GET", urlApi)
        .then(function (response) {
          setData(response.data);
          setLoad(false);
        })
        .catch(function (error) {
          console.log("error");
          setLoad(false);
        });
    }, [urlApi]);

    const nav = (i) => {
      console.log(location.pathname);
      if (location.pathname.includes("/rol/6/")) {
        navigate(`./step/${i.step}`);
      } else {
        navigate(`/rol/6/menu/ManageProcedures`);
        const timeoutId = setTimeout(() => {
          navigate(`/rol/6/procedure-type/1/step/${i.step}`);
          return () => clearTimeout(timeoutId);
        }, 2500);
      }
      // dialogClose()
    };
    return load ? (
      <Circle spinnerColor="text-orange-400" />
    ) : (
      <SimpleTableManagement
        header={[
          {
            name: "codigoDeInterno",
            label: "Código de interno",
          },
          {
            name: "stepName",
            label: "Nombre de paso",
          },
          {
            name: "edit",
            type: "action",
            label: "Acciones",
            actions: [
              {
                label: "Añadir campo",
                icon: "fas fa-share",
                action: nav,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        data={data}
        labelGrafic="trámite"
      />
    );
  };

  function handleClickMonth(
    label,
    data,
    urlApi,
    params,
    generalData,
    urlApiMain
  ) {
    openDialog(
      <div style={{ width: "80vw" }}>
        <ChartContainer
          // title={label}
          title={`${label} - Mes: ${data?.label}`}
          type="SubChart"
          urlApi={`${urlApi}Month`}
          generalData={{
            ...generalData,
            fieldValue: label,
            numMonth: data?.id,
            onClick: console.log(generalData),
          }}
          addParams={{
            dateQuery: "fechaDeFacturación",
            month: data?.id,
            value: label,
            ...params,
          }}
          ContentData={ContentData}
          actualData={{
            ...generalData,
            fieldValue: label,
            numMonth: data?.id,
            urlApiMain: urlApiMain.replace(urlApi, `${urlApi}Table`),
          }}
        />
      </div>
    );
  }

  function handleClickClient(label, _, urlApi, params) {
    openDialog(
      <div style={{ width: "80vw" }}>
        <ChartContainer
          title={`Mes: ${label.label}`}
          type="SubChart"
          urlApi={`${urlApi.substring(0, urlApi.length - 6)}Number`}
          addParams={{
            dateQuery: "fechaDeFacturación",
            month: label.id,
            ...params,
          }}
        />
      </div>
    );
  }

  function handleReportExcel() {
    openDialog(<ExportProcedureExcel openDialog={openDialog} />);
  }

  return (
    <div>
      <div className="flex justify-between mb-2">
        <div className="flex gap-4 items-center">
          <h3>Gráfica de gerencia: </h3>
          <Select
            options={graficas}
            value={graficas.find((option) => option.value === chartType)}
            onChange={handleChartTypeChange}
          />
          <h3>Gestión:</h3>
          <Select
            options={gestiones}
            value={gestiones.find((option) => option.value === year)}
            onChange={handleYearChange}
          />
        </div>
        <Button
          content={"Registro de internos"}
          onClick={() => handleReportExcel()}
        >
          <i className="fas fa-book"></i>
        </Button>
      </div>
      {
        {
          Clientes: (
            <ChartContainer
              title="Cantidad de tramites por cliente"
              urlApi={`/procedure/company/year/${year}/chart`}
              filters={[
                ...filtersDefaultChart,
                {
                  name: "months",
                  label: "Todos los meses",
                  options: [
                    {
                      label: "Enero",
                      name: "1",
                    },
                    {
                      label: "Febrero",
                      name: "2",
                    },
                    {
                      label: "Marzo",
                      name: "3",
                    },
                    {
                      label: "Abril",
                      name: "4",
                    },
                    {
                      label: "Mayo",
                      name: "5",
                    },
                    {
                      label: "Junio",
                      name: "6",
                    },
                    {
                      label: "Julio",
                      name: "7",
                    },
                    {
                      label: "Agosto",
                      name: "8",
                    },
                    {
                      label: "Septiembre",
                      name: "9",
                    },
                    {
                      label: "Octubre",
                      name: "10",
                    },
                    {
                      label: "Noviembre",
                      name: "11",
                    },
                    {
                      label: "Diciembre",
                      name: "12",
                    },
                  ],
                },
              ]}
              addParams={{ dateQuery: "a2FechaDeAceptación" }}
              tableHeaders={[
                {
                  label: "Compañia",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          Liquidadores: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por liquidador"
              urlApi={`/Procedure/field/designacionDeLiquidador/year/${year}/chart`}
              generalData={{ year, fieldname: "designacionDeLiquidador" }}
              addParams={{ dateQuery: "a2FechaDeAceptación" }}
              filters={[
                {
                  name: "procedureType",
                  url: "/procedureType",
                  default: "Todos los tramites",
                  valueOption: "id",
                  labelOption: "name",
                },
                {
                  name: "sucursal",
                  label: "Todos las sucursales",
                  options: [
                    {
                      label: "La Paz",
                      name: "LP",
                    },
                    {
                      label: "Santa Cruz",
                      name: "SC",
                    },
                  ],
                },
              ]}
              tableHeaders={[
                {
                  label: "Liquidador",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          Gestores: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por gestor"
              urlApi={`/Procedure/field/asignarGestor/year/${year}/chart`}
              addParams={{ dateQuery: "a2FechaDeAceptación" }}
              filters={[
                {
                  name: "procedureType",
                  url: "/procedureType",
                  default: "Todos los tramites",
                  valueOption: "id",
                  labelOption: "name",
                },
                {
                  name: "sucursal",
                  label: "Todos las sucursales",
                  options: [
                    {
                      label: "La Paz",
                      name: "LP",
                    },
                    {
                      label: "Santa Cruz",
                      name: "SC",
                    },
                  ],
                },
                {
                  name: "months",
                  label: "Todos los meses",
                  options: [
                    {
                      label: "Enero",
                      name: "1",
                    },
                    {
                      label: "Febrero",
                      name: "2",
                    },
                    {
                      label: "Marzo",
                      name: "3",
                    },
                    {
                      label: "Abril",
                      name: "4",
                    },
                    {
                      label: "Mayo",
                      name: "5",
                    },
                    {
                      label: "Junio",
                      name: "6",
                    },
                    {
                      label: "Julio",
                      name: "7",
                    },
                    {
                      label: "Agosto",
                      name: "8",
                    },
                    {
                      label: "Septiembre",
                      name: "9",
                    },
                    {
                      label: "Octubre",
                      name: "10",
                    },
                    {
                      label: "Noviembre",
                      name: "11",
                    },
                    {
                      label: "Diciembre",
                      name: "12",
                    },
                  ],
                },
              ]}
              tableHeaders={[
                {
                  label: "Gestor",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          Canales: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por canal"
              urlApi={`/Procedure/field/canal(Rojo,AmarilloOVerde)/year/${year}/chart`}
              addParams={{ dateQuery: "a2FechaDeAceptación" }}
              filters={filtersDefaultChart}
              tableHeaders={[
                {
                  label: "Canal",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          Mercadería: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por tipo de mercadería"
              urlApi={`/Procedure/field/tipoDeMercadería/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "a2FechaDeAceptación" }}
              tableHeaders={[
                {
                  label: "tipo de mercadería",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          "Aduanas De Despachos": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por aduana de despacho"
              urlApi={`/Procedure/field/a4AduanaDeDespacho/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "a2FechaDeAceptación" }}
              tableHeaders={[
                {
                  label: "Compañia",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
        }[chartType]
      }
    </div>
  );
};

export default Management;
