import { H3 } from "../../../components/Text";
import { Button } from "../../../components/Buttons";
import { DialogConfirmation } from "../../../components/Modal";
import { useModal } from "../../../contexts/ModalContext";
import { useDialog } from "../../../contexts/DialogContext";
import CallServiceTable from "../../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";
import { Request } from "../../../hooks/Request";
import RenderPdfPlanilla from "../../../containers/ManegeProcedure/RenderPdfPlanilla";
import RenderPdfPlanillaFactura from "../../../containers/ManegeProcedure/RenderPdfPlanillaFactura";
import { useParams } from "react-router-dom";
import RenderPdfFactura from "../../../containers/ManegeProcedure/RenderPdfFactura";
import { useEffect, useState } from "react";
import { Circle } from "../../../components/Animation";

const FolderPayment = () => {

  const { procedureId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function createSpent(reload) {
    openModal(<FormSpent reload={reload} onClose={onClose} procedureId={procedureId} />);
  }

  function addNote(reload) {
    openModal(<FormAddNote reload={reload} onClose={onClose} procedureId={procedureId} />);
  }

  function editSpent(spent, reload) {
    openModal(<FormSpent spent={spent} reload={reload} onClose={onClose} procedureId={procedureId} />);
  }

  function deleteSpent(spet, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el registro?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Expenses/${spet.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }


  function listDocumentPdfPlanilla(reload) {
    openDialog(<RenderPdfPlanilla procedureId={procedureId} reload={reload} />);
    // openDialog(<RenderPdfPlanilla />);
  }
  function listDocumentPdfPlanillaFactura(reload) {
    openDialog(<RenderPdfPlanillaFactura procedureId={procedureId} reload={reload} />);
    // openDialog(<RenderPdfPlanilla />);
  }

  function listDocumentPdfFactura(reload) {
    openDialog(
        <RenderPdfFactura procedureId={procedureId} reload={reload} />
  );
    // openDialog(<RenderPdfPlanilla />);
  }

  const { data } = Request({
    urlApi: `/procedure/${procedureId}/general`,
  });


  return (
    <>
      <div className="flex flex-wrap gap-10 bg-blue-100 p-5 rounded-lg my-5">
        <p><span className="font-bold">Nro de Carpeta: </span>{ data?.nroInterno }</p>
        <p><span className="font-bold">DIM: </span>{ data?.nroDim || "---" }</p>
        <p><span className="font-bold">Cliente: </span>{ data?.companyName || "---" }</p>
        {/* <p><span className="font-bold">Referencia del Cliente: </span>{ data?.refCliente || "---" }</p> */}
      </div>
      <CallServiceTable
        addUrl={`ProcedureId=${procedureId}`}
        // onClick={viewAppointment}
        component={({ reload }) => {
            return (
              <>
               {/* <Button className="py-2" onClick={() => createCite(reload)}> */}
              <Button className="py-2 bg-[#FFB458]" onClick={() => addNote(reload)}>
                <i className="fas fa-pencil-alt mr-2"></i>
                Añadir nota
              </Button>
              <Button className="py-2 bg-[#4A75AE]" onClick={() => listDocumentPdfFactura(reload)}>
                Ver Factura
              </Button>
              <Button className="py-2 bg-[#4A75AE]" onClick={() => listDocumentPdfPlanilla(reload)}>
              {/* <Button className="py-2 bg-[#4A75AE]"> */}
                Ver Planilla
              </Button>
              <Button className="py-2 bg-[#4A75AE]" onClick={() => listDocumentPdfPlanillaFactura(reload)}>
              {/* <Button className="py-2 bg-[#4A75AE]"> */}
                Ver Planilla y Factura
              </Button>
              <Button className="py-2" onClick={() => createSpent(reload)}>
                <i className="fas fa-dollar-sign mr-2"></i>
                Adicionar
              </Button>
              </>
            );
          }}
        filters={[
          {
            name: "status",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Sin Cobrar",
                name: "Sin Cobrar",
              },
              {
                label: "Cobrado",
                name: "Cobrado",
              },
            ],
          },
          {
            name: "type",
            label: "Todos los tipos",
            default: "Todos los tipos",
            options: [
              {
                label: "Factura",
                name: "Factura",
              },
              {
                label: "Planilla",
                name: "Planilla",
              },
            ],
          },
        ]}
        urlApi="/Expenses/pag"
        header={[
          {
            label: "Sigla",
            name: "sigla",
            filter: true,
          },
          {
            label: "Fecha",
            name: "creationDate",
            type: "date"
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {
              return (
                  <span className={`block w-fit px-2 py-1 rounded-full border ${ status==="Cobrado" ? "bg-green-100 border-green-500 text-green-500" : "bg-red-100 border-red-500 text-red-500" }`}>
                    { status }
                  </span>
              );
            },
          },
          {
            label: "Concepto",
            name: "concepto",
            filter: "true"
          },
          {
            label: "Tipo",
            name: "tipo",
            type: "custom",
            builder: (tipo) => {
              tipo = tipo==="favClient" ? "A favor del cliente" : tipo
              return (
                  <span >
                    { tipo }
                  </span>
              );
            },
          },
          {
            label: "Monto",
            name: "monto",
          },
          {
            name: "edit",
            type: "action",
            label: "Editar",
            actions: [
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editSpent,
                color: "text-[#1d4ed8]",
              },
            ],
          },
          {
            name: "delete",
            type: "action",
            label: "Eliminar",
            actions: [
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteSpent,
                color: "text-red-500",
              },
            ],
          },
        ]}
      />
    </>
  );
};

const FormAddNote = (props) => {
  const { reload, procedureId } = props;

  const { data } = Request({
    urlApi: `/Logadex/procedure/${procedureId}/someData`,
  });

  const notas = data?.dataSets.notaDeGasto;


  const [inputValue, setInputValue] = useState("");
  const [listNotes, setListNotes] = useState([]);

  useEffect(() => {
    if (notas) {
      setListNotes(notas.split("(*)"));
    }
  }, [notas]);

  const inputChange = ( {target} ) => {
    setInputValue(target.value);
  }

  async function handleDeleteItem(valor) {
    setListNotes(
      listNotes.filter((item) => item !== valor)
    );

    const notes = listNotes.filter((item) => item !== valor)

    let formData=[{
      fieldname: "notaDeGasto",
      value: notes.join("(*)"),
    }]

  
    await requestAuth("post", `/DataSet/procedure/${procedureId}/new`, formData)
      .then((res) => {
        reload();
        toast.success("Se guardaron los cambios correctamente");
      })
      .catch(() => {
        toast.error("Se produjo un error");
      });

  }

  async function createNote() {

    if(inputValue.trim().length===0) return
    
    setListNotes([...listNotes, inputValue])

    const notes = [...listNotes, inputValue]

    let formData=[{
      fieldname: "notaDeGasto",
      value: notes.join("(*)"),
    }]

  
    await requestAuth("post", `/DataSet/procedure/${procedureId}/new`, formData)
      .then((res) => {
        reload();
        toast.success("Se guardaron los cambios correctamente");
        setInputValue("")
      })
      .catch(() => {
        toast.error("Se produjo un error");
      });
  
      
    }

    

  return (
    <>
      <h3 className="my-5 text-2xl text-center font-bold">Notas</h3>

      <div className="w-[80%] mx-auto">
        <p className="my-2">
          Nueva nota
        </p>
        <input 
          type="text" 
          className="w-full bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 disabled:opacity-100 disabled:bg-gray-300"
          value={ inputValue }
          onChange={ inputChange }
        />
        <Button className="py-2 my-4 block mx-auto" onClick={createNote}>
          Añadir <i className="fas fa-plus"></i>
        </Button>

      </div>

      
      <h3 className="my-5 text-xl text-center font-bold">Lista de Notas</h3>


      <div className="w-[80%] mx-auto">
        {
          listNotes?.map((valor) => (
            <div key={valor} className="bg-white p-5 flex gap-5 mb-2">
              <p className="w-full">
                {valor}
              </p>
              <button onClick={() => handleDeleteItem(valor)}>
                <i className="fas fa-trash text-[#ff2d55]"></i>
              </button>
            </div>
          ))
        }
                
      </div>

    </>
  );
};

const FormSpent = (props) => {
  const { spent, onClose, reload, procedureId } = props;

  async function handleInCharge(values) {

    
    if (values?.concepto_isNew) {

      const object = {
        0: {
          condition: "Gasto",
          conditionType: "listValidate",          
          label: "Sigla",
          name: "sigla",
          reference: "type"
        },
        btnSecondary: false,
        sigla: values.sigla,
        typeObj: {
          label: "Gasto",
          value: "Gasto"
        }
      }

      const dropData = {
        name: values.concepto,
        data: JSON.stringify(object),
        type: "Gasto",
        dropdownListLabel: "list-expenses"
      }
      
      await requestAuth(
        "post",
        "/Dropdown/Option",
        dropData
      )
        .then(async (result) => {
          await handleRegisterNew({
            ...values,
          });
        })
        .catch((response) => {
          toast.error(
            response.response.data
              ? response.response.data
              : "Se produjo un error al crear"
          );
        });

    } else {
      await handleRegister(values);
    }
  }

  async function handleRegisterNew(values) {

    const formData = {
      body: null,
      sigla: values.sigla,
      status: "Sin Cobrar",
      concepto: values.concepto,
      tipo: values.tipo,
      monto: values.monto,
      procedureId: procedureId,
    }
    await requestAuth(
      "post",
      "/Expenses",
      formData
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Se guardó con éxito");
      })
      .catch((response) => {
        toast.error(
          response.response.data
            ? response.response.data
            : "Se produjo un error al crear"
        );
      });
  }
  async function handleRegister(values) {


    let concepto = ""
      let monto = ""
      let sigla = ""
      let tipo = ""

      if(values.conceptoObj){
        let dataGasto = JSON.parse(values.conceptoObj.data)

        concepto = values.concepto
        monto = values.monto
        sigla = dataGasto.sigla
        tipo = values.tipo
      }else{
        concepto = values.concepto
        monto = values.monto
        sigla = spent.sigla
        tipo = values.tipo
      }


      const formData = spent ? {
        body: values.body,
        sigla: sigla,
        status: spent.status,
        concepto: concepto,
        tipo: tipo,
        monto: monto,
        procedureId: procedureId,
        id: spent.id
      }
      : {
        body: null,
        sigla: sigla,
        status: "Sin Cobrar",
        concepto: concepto,
        tipo: tipo,
        monto: monto,
        procedureId: procedureId,
      }
      await requestAuth(
        spent ? "put" : "post",
        "/Expenses",
        formData
      )
        .then(() => {
          reload();
          onClose();
          toast.success("Se guardó con éxito");
        })
        .catch((response) => {
          toast.error(
            response.response.data
              ? response.response.data
              : "Se produjo un error al crear"
          );
        });
  }

  return (
    <FormDefault
      tittle={spent ? "Editar Gasto" : "Crear nuevo Gasto"}
      fields={[        
        {
            label: "Gasto",
            name: "concepto",
            typeInput: "selectNewOption",
            urlApi: "/Dropdown/list-expenses/options",
            value: "name",
            labelOption: "name",
        },
        {
          label: "Sigla",
          name: "sigla",
          conditionType: "listValidate",
          reference: "concepto_isNew",
          condition: "true",
          color: "#8A8D92",
        },
        {
          label: "Tipo de Gasto",
          name: "tipo",
          type: "select",
          typeInput: "select",
          options: [
            {
              label: "Planilla",
              value: "Planilla",
            },
            {
              label: "Factura",
              value: "Factura",
            },
            {
              label: "A favor del cliente",
              value: "favClient",
            },
          ],
        },
        {
          label: "Monto",
          name: "monto",
          type: "number"
        },
        
      ]}
      initialValues={{
        concepto: spent?.concepto ?? "",
        tipo: spent?.tipo ?? "Planilla",
        monto: spent?.monto ?? "",
      }}
      validationSchema={Yup.object().shape()}
      onSubmit={handleInCharge}
      buttonName={spent ? "Editar" : "Crear"}
    />
  );
};

export default FolderPayment;
