import * as Yup from "yup";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { filtersDefault } from "../../utils/constant";

const Regularization = ({ type, procedureTypeId }) => {
  const { openModal, onClose } = useModal();
  function handleRegularization(procedure, reload) {
    openModal(
      <FormRegularization
        procedureTypeId={procedureTypeId}
        procedure={procedure}
        reload={reload}
        onClose={onClose}
      />
    );
  }
  return (
    <CallServiceTable
      filters={filtersDefault}
      urlApi={`/bruseco/regularization/procedures`}
      addUrl={`procedureType=${procedureTypeId}&TypeRegularization=${type}`}
      // onClick={handleRegularization}
      header={[
        {
          name: "numberString",
          label: "Trámite",
        },
        {
          name: "diasRegularizacion",
          label: "Días restantes regularización",
          type: "custom",
          builder: (value, _, head) => {
            if (head.regimenPatron === "IMA4 DESPACHO ANTICIPADO") {
              const number = parseInt(
                head.timeDiasRegularizacion.split(".")[0]
              );
              return (
                <div
                  className={`text-white ${
                    head.estadoRegularizacion === "Regularizado"
                      ? "bg-white text-gray-500"
                      : number <= 5
                      ? "bg-[#fd3838A1]"
                      : number <= 15
                      ? "bg-[#f3e40c51] text-[#747474]"
                      : number <= 18
                      ? "bg-[#4fce4421] text-gray-500"
                      : "text-gray-500"
                  } text-center rounded-full w-fit py-1 px-4`}
                >
                  {head.estadoRegularizacion === "Regularizado"
                    ? "-"
                    : head.estadoRegularizacion}
                </div>
              );
            } else {
              const number = parseInt(
                head.timeDiasRegularizacion.split(".")[0]
              );
              return (
                <div
                  className={`text-white ${
                    head.estadoRegularizacion === "Regularizado"
                      ? "bg-white text-gray-500"
                      : number <= 5
                      ? "bg-[#fd3838A1]"
                      : number <= 15
                      ? "bg-[#f3e40c51] text-[#747474]"
                      : number <= 18
                      ? "bg-[#4fce4421] text-gray-500"
                      : "text-gray-500"
                  } text-center rounded-full w-fit py-1 px-4`}
                >
                  {head.estadoRegularizacion === "Regularizado"
                    ? "-"
                    : head.diasAbandono}
                </div>
              );
            }
          },
        },
        {
          name: "format",
          label: "Tipo de trámite",
        },
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        { name: "dimNumber", label: "Nro. DIM/DEX" },
        {
          name: "fechaYHoraDeArribo",
          label: "Fecha arribo",
        },
        {
          name: "fechaYHoraDeRecepcion",
          label: "Fecha recepción",
        },
        {
          name: "billNumber",
          label: "Factura",
        },
        {
          name: "companyName",
          label: "Cliente",
        },
        {
          name: "procedureTypeName",
          label: "Patrón",
        },
        {
          name: "currentStepName",
          label: "Paso Actual",
          type: "custom",
          builder: (value, _, data) => {
            if (data.step > data.totalSteps) {
              return "Terminado";
            }
            return value;
          },
        },
        {
          name: "currentStep",
          label: "Progreso",
          type: "custom",
          builder: (value, _, data) => {
            if (data.step > data.totalSteps) {
              return "Terminado";
            }
            return value;
          },
        },
        // {
        //   name: "diasAbandono",
        //   label: "Días restantes abandono",
        //   type: "custom",
        //   builder: (value, _, head) => {
        //     const number = parseInt(head.timeDiasAbandono.split(".")[0]);
        //     return (
        //       <div
        //         className={`text-white ${
        //           number <= 10
        //             ? "bg-[#fd3838A1]"
        //             : number <= 25
        //             ? "bg-[#f3e40c51] text-[#747474]"
        //             : number <= 45
        //             ? "bg-[#4fce4421] text-gray-500"
        //             : "text-gray-500"
        //         } text-center rounded-full w-fit py-1 px-4`}
        //       >
        //         {value}
        //       </div>
        //     );
        //   },
        // },
        // {
        //   name: "estadoRegularizacion",
        //   label: "Estado",
        //   type: "custom",
        //   builder: (value) => {
        //     return (
        //       <div
        //         className={`text-white ${
        //           value === "Sin regularizar" || value === "Pendiente"
        //             ? "bg-[#fd3838A1]"
        //             : value === "Regularizado"
        //             ? "bg-[#4fce4421] text-gray-500"
        //             : ""
        //         } text-center rounded-full w-fit py-1 px-4 `}
        //       >
        //         {value}
        //       </div>
        //     );
        //   },
        // },
        // ...headerListContent,
      ]}
    />
  );
};

const FormRegularization = (props) => {
  const { procedure, procedureTypeId, reload, onClose } = props;

  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", "DIM Regularizada");
    formData.append("File", values.file);
    formData.append("originalName", values.file.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        onSubmit({
          ...values,
          file: res.data.url,
          FileId: res.data.id,
        });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function onSubmit(values) {
    await requestAuth("post", "/dataset", [
      {
        FieldId: procedureTypeId === 1 ? 181 : 595,
        FileId: values.FileId,
        ProcedureId: procedure.id,
        Value: values.file,
      },
      {
        FieldId: procedureTypeId === 1 ? 594 : 596,
        ProcedureId: procedure.id,
        Value: values.observation,
      },
    ])
      .then(() => {
        reload();
        onClose();
        toast.success("Tramite regularizado");
      })
      .catch(() => {
        toast.error("Se ha producido un error");
      });
  }
  return (
    <FormDefault
      tittle={`Regularizar el trámite (${
        procedure.internCode || procedure.name
      })`}
      fields={[
        {
          label:
            procedureTypeId === 1
              ? "DIM regularizada"
              : "Constancia de salida de exportación",
          name: "file",
          type: "file",
          typeInput: "file",
        },
        {
          label:
            procedureTypeId === 1
              ? "Observaciones de la DIM regularizada"
              : "Observaciones de la constancia",
          name: "observation",
          placeholder: "Introduzca las observaciones",
          type: "textArea",
          typeInput: "input",
        },
      ]}
      initialValues={{
        file:
          procedureTypeId === 1
            ? procedure?.dimRegularizada && procedure.dimRegularizada !== "-"
              ? procedure.dimRegularizada
              : null
            : procedure?.constanciaDeSalidaDeExportacion &&
              procedure.constanciaDeSalidaDeExportacion !== "-"
            ? procedure.constanciaDeSalidaDeExportacion
            : null,
        observation:
          procedureTypeId === 1
            ? procedure?.observacionesDeLaDimRegularizada ?? ""
            : procedure?.observacionesDeLaConstanciaDeSalidaDeExportacion ?? "",
      }}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required("Campo requerido.").nullable(),
      })}
      onSubmit={(values) =>
        typeof values.dimRegularizada === "string"
          ? onSubmit(values)
          : uploadFile(values)
      }
      buttonName="Regularizar"
    />
  );
};

export default Regularization;
