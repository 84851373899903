// import './RenderPdf.css'
import * as dayjs from "dayjs";
import 'dayjs/locale/es';
import { Request } from "../../hooks/Request";
import logotipo from "../../assets/logo-logadex.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";


const PdfPlanilla = (props) => {
  const { procedureId } = props;

  const { data: dataPlanilla, loading } = Request({
    urlApi: `/expenses?procedureId=${procedureId}&type=Planilla`,
  });
  const { data: dataFactura, loading: loadingFactura } = Request({
    urlApi: `/expenses?procedureId=${procedureId}&type=Factura`,
  });
  const { data: dataFavClient, loading: loadingFavClient } = Request({
    urlApi: `/expenses?procedureId=${procedureId}&type=favClient`,
  });
  const { data, loading: loadingData } = Request({
    urlApi: `/Logadex/procedure/${procedureId}/someData`,
  });

  if (!loading || !loadingFactura || !loadingData || !loadingFavClient) {
    return <Circle />;
  }

  return (
    <RenderPdfPlanilla dataPlanilla={dataPlanilla} dataFactura={dataFactura} data={data} dataFavClient={dataFavClient}/>
  )
}


const RenderPdfPlanilla = (props) => {
  const { dataPlanilla, dataFactura, data, dataFavClient } = props;

  const localizedFormat = require("dayjs/plugin/localizedFormat");

  dayjs.extend(localizedFormat);
  dayjs.locale('es');



  let subTotal=0;
  let totalFavClient=0;
  let totalFactura=0;
  const tazaCambio = data?.dataSets.tipoCambio;
  const notes = data?.dataSets.notaDeGasto?.split("(*)");


  if(dataPlanilla){
    for (const objeto of dataPlanilla) {
      subTotal += objeto.monto;
    }
  }
  if(dataFavClient){
    for (const objeto of dataFavClient) {
      totalFavClient += objeto.monto;
    }
  }

  subTotal = parseFloat(subTotal.toFixed(2))
  totalFavClient = parseFloat(totalFavClient.toFixed(2))

  if(dataFactura){
    for (const objeto of dataFactura) {
      totalFactura += objeto.monto;
    }
  }

  totalFactura = parseFloat(totalFactura.toFixed(2))
  let totalGeneral=subTotal+totalFactura;
  totalGeneral = parseFloat(totalGeneral.toFixed(2))
  let totalFinal=totalGeneral-totalFavClient;

  let totalGeneralUSD=0;
  let totalGeneralUSDFormat=""
  if(tazaCambio){
    
    totalGeneralUSD=totalGeneral / tazaCambio;
    totalGeneralUSDFormat=totalGeneralUSD.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  totalGeneral = parseFloat(totalGeneral.toFixed(2))
  totalFinal = parseFloat(totalFinal.toFixed(2))
  
  let subTotalFormat = subTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let totalFavClientFormat = totalFavClient.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let totalFacturaFormat = totalFactura.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let totalGeneralFormat = totalGeneral.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let totalFinalFormat = totalFinal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });


  const numeros = require("numeros_a_palabras");

  numeros.numero2word().Config._setSingular("BOLIVIANO");
  numeros.numero2word().Config._setPlural("BOLIVIANOS");
  numeros.numero2word().Config._setCentsSingular("CENTAVO");
  numeros.numero2word().Config._setCentsPlural("CENTAVOS");

  
  const { data: subTotalLiteral, loading } =  Request({
    urlApi: `/utility/NumberToLiteral/${subTotal}`,
  });
  
  const { data: totalLiteral, loading: loadingTotal } =  Request({
    urlApi: `/utility/NumberToLiteral/${totalFinal}`,
  });
  
  if (!loading || !loadingTotal) {
    return <Circle />;
  }


  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        
        <View>
          <View
            width="15%"
            // border={{ right: true }}
            justifyContent="start"
            alignItems="center"
          >
            <Image src={logotipo} style={{ width: '100px' }} />
          </View>
          
          <View width="50%" padding="3px" flexDirection="column">
            <Text 
              bold 
              color="#0000A0"
              fontSize="10px" 
              // border={{ right: true }}
              textAlign="center"
            >
              AGENCIA DESPACHANTE DE ADUANA S.R.L.
            </Text>
            <Text 
              textAlign="center"
              color="#0000A0"
              style={{ fontFamily: 'Courier New, Courier, monospace' }}
            >
              Av.Jaimes Freyre Nro 2354 Edf. Torre
            </Text>
            <Text 
              textAlign="center"
              color="#0000A0"
              style={{ fontFamily: 'sans-serif, Arial, Helvetica' }}
            >
              Telefono: 22417290 - 71551797
            </Text>
            <Text 
              textAlign="center"
              color="#0000A0"
              style={{ fontFamily: 'Courier New, Courier, monospace' }}
            >
              La Paz - Bolivia
            </Text>
            
          </View>

          <View width="35%" padding="3px" flexDirection="column" alignItems="center">
            <View 
              width="100%"            
              border={{ right: true, left: true, top: true, bottom: true }}              
              justifyContent="center"
              padding="5px"
            >
              <Text 
                bold 
                fontSize="10px" 
                textAlign="center"
              >
                PLANILLA N°:
              </Text>
              <Text
                bold
                color="#ff0000"
                fontSize="12px"
              >
                {data?.num}
              </Text>
            </View>
            
            <Text 
              textAlign="center"
              bold
              fontSize="16px"
            >
              ORIGINAL
            </Text>
            
          </View>

        </View>

        <View>
          <Text 
            bold 
            // color="#0B4BCE"
            fontSize="10px" 
            // textAlign="center"
            minWidth="10%"
            >
            Fecha:
          </Text>
          <Text
            fontSize="10px" 
            color="#737373"
            // fontSize="12px"
          >
            {data?.registerDate ? dayjs(data?.registerDate).format("LLLL") : ""}
          </Text>
        </View>

        <View>
          <Text 
            bold 
            // color="#0B4BCE"
            fontSize="10px" 
            // textAlign="center"
            minWidth="10%"
            >
            Señor(es):
          </Text>
          <Text
            fontSize="10px" 
            color="#737373"
            // fontSize="12px"
          >
            {data?.company.razonSocial}
          </Text>
        </View>

        <View>
          <Text 
            bold 
            // color="#0B4BCE"
            fontSize="10px" 
            // textAlign="center"
            minWidth="10%"
            >
            D.U.I. N°:
          </Text>
          <Text
            fontSize="10px" 
            color="#737373"
            // fontSize="12px"
          >
            {data?.dataSets.nroDim}
          </Text>
        </View>

        <View>
          <View 
            width="33.3%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="10%"
              >
              T/C USD:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {tazaCambio}
            </Text>
          </View>
          <View 
            width="33.3%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="10%"
            >
              Valor CIF UsD:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {data?.dataSets.cifUsd}
            </Text>
          </View>
          <View 
            width="33.3%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="6%"
              >
              Bs.:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {data?.dataSets.cifBs}
            </Text>
          </View>
        </View>

        {/* tabla */}

        <View 
          width="100%"          
          justifyContent="center"
          padding="5px"
        >
          <Text 
            bold 
            color="#C90000"
            fontSize="16px" 
            textAlign="center"
          >
            PLANILLA DE DESPACHO
          </Text>
        </View>


        {/* TABLA1 */}

        {/* <View
          border={{ bottom: true, top: true }}
          style={{ marginTop: "5px", backgroundColor: "#D4D4D4" }}
        >
          <Text 
            bold
            minWidth="15%"
          >
            N° BULTOS
          </Text>
          <Text 
            bold
            minWidth="85%"
          >
            DESCRIPCIÓN
          </Text>
        </View>

        <View 
          // border={{ right: true, bottom: true, left: true }}
          padding="5px"
        >
          <Text 
            bold
            color="#C90000"
            fontSize="11px" 
          >
            CORRESPONDE A DESPACHO ADUANERO DE:
          </Text>
        </View>

        <View 
          // border={{ right: true, bottom: true, left: true }}
          // padding="5px"
        >
          <Text 
            // bold
            minWidth="15%"
            style={{ 
              textAlign: "right" 
            }}
            // textAlign="right"
          >
            1
          </Text>
          <Text 
            // bold
            minWidth="85%"
            style={{ 
              textAlign: "right" 
            }}
            // textAlign="right"
          >
            Articulos Varios
          </Text>
        </View> */}

        {/* TABLA2 */}

        <View style={{marginTop: "5px"}} flexDirection="column">

          {/* HEADER */}
          <View
            style={{backgroundColor: "#D4D4D4" }}
            // border={{top: true, bottom: true}}
            borderContent
          >
            <View 
              width="80%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true}}

            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
                style={{marginRight: "20px"}}
              >
                CONCEPTO
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="center"
              alignItems="center"
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
              >
                IMPORTES
              </Text>
            </View>

          </View>

          {/* CONTENIDO */}

          {
            
            dataPlanilla?.map((item) => (
              <View key={item.id}
                border={{right: true, left: true}}
              >
                <View 
                  width="80%"
                  justifyContent="flex-end"
                  alignItems="center"
                  border={{right: true}}
                >
                  <Text 
                    bold
                    textAlign="center"
                    fontSize="9px"
                    color="#343739"
                    style={{marginRight: "10px"}}
                  >
                    {item.concepto}: Bs.
                  </Text>
                </View>

                <View 
                  width="20%"
                  justifyContent="flex-end"
                  alignItems="center"
                  // border={{right: true}}
                >
                  <Text 
                    bold
                    textAlign="center"
                    fontSize="9px"
                    color="#343739"
                    style={{marginRight: "10px"}}
                  >
                    {
                      item.monto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    }
                  </Text>
                </View>

              </View>
            ))
          }
          
          

          <View
            border={{bottom: true}}
          ></View>



        </View>

        {/* FIN TABLA */}

        <View
          border={{ top: true }}
          style={{ marginTop: "7px" }}

        >
          <View 
            width="70%"
            
          >
            {/* <Text 
              bold 
              fontSize="10px" 
              minWidth="10%"
              >
              Usuario:
            </Text>
            <Text
              fontSize="9px" 
              color="#737373"
            >
              LEDEZMA
            </Text> */}
          </View>
          <View 
            width="30%"
            justifyContent="flex-end"
          >
            <Text 
              bold 
              fontSize="10px" 
              minWidth="15%"
            >
              Sub Total Bs.:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              style={{marginRight: "10px"}}
            >
              {subTotalFormat}
            </Text>
          </View>
        </View>

        <View>
          <Text 
            bold 
            fontSize="10px" 
            minWidth="5%"
            >
            SON:
          </Text>
          <Text
            fontSize="10px" 
            color="#737373"
            // fontSize="12px"
          >
            {
              subTotalLiteral.toUpperCase()
            }
          </Text>
        </View>

        {/* footer */}

        <View borderContent style={{ marginTop: "15px" }}>
          <View
            width="60%"
            border={{ right: true }}
            // padding="10px"
            flexDirection="column"
          >
            <Text bold>
              NOTA:
            </Text>
            {
              notes?.map((valor, indice) => (
                <Text key={indice} color="#737373" fontSize="8px">
                  {indice+1}. {valor} 
                  {/* {valor}  */}
                </Text>
              ))
            }
            
          </View>
          

          <View width="40%" flexDirection="column">
            <View>
              <Text minWidth="50%" bold color="#000000" >
                Total Plla. Despacho:
              </Text>
              <View
              width="50%"
                justifyContent="flex-end"
              >
                <Text color="#737373">{subTotalFormat}</Text>
              </View>
            </View>
            <View>
              <Text minWidth="50%" bold color="#000000">
                Imp.Factura:
              </Text>
              <View
              width="50%"
                justifyContent="flex-end"
              >
                <Text color="#737373">{totalFacturaFormat}</Text>
              </View>
            </View>
            <View>
              <Text minWidth="50%" bold color="#000000">
                TOTAL GENERAL Bs.:
              </Text>
              <View
              width="50%"
                justifyContent="flex-end"
              >
                <Text color="#737373">{totalGeneralFormat}</Text>
              </View>
            </View>
            <View>
              <Text minWidth="50%" bold color="#000000">
                PAGOS HECHOS POR EL IMPORTADOR:
              </Text>
              <View
              width="50%"
                justifyContent="flex-end"
              >
                <Text color="#737373">{totalFavClientFormat}</Text>
              </View>
            </View>
            <View>
              <Text minWidth="50%" bold color="#000000">
                TOTAL FINAL:
              </Text>
              <View
              width="50%"
                justifyContent="flex-end"
              >
                <Text color="#737373">{totalFinalFormat}</Text>
              </View>
            </View>
            <View>
              <Text minWidth="50%" bold color="#000000">
                TOTAL GENERAL Usd.:
              </Text>
              <View
              width="50%"
                justifyContent="flex-end"
              >
                <Text color="#737373">{totalGeneralUSDFormat}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ marginTop: "15px" }}>
          <Text 
            bold 
            fontSize="10px" 
            minWidth="5%"
            >
            SON:
          </Text>
          <Text
            fontSize="10px" 
            color="#737373"
          >
            {/* SIETE MIL SEICIENTOS CUARENTA Y SIETE 90/100 BOLIVIANOS */}
            {
              totalLiteral.toUpperCase()
            }
          </Text>
        </View>

        

      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default PdfPlanilla;
