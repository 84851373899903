import * as Yup from "yup";

export const validateType = {
  text: Yup.string().required("Campo requerido"),
  controlField: Yup.string().required("Campo requerido"),
  selectGet: Yup.string().required("Campo requerido"),
  file: Yup.mixed().required("Campo requerido.").nullable(),
  fileRead: Yup.mixed().required("Campo requerido.").nullable(),
  fileExcel: null,
  // fileExcel: Yup.string().required('Campo requerido'),
  number: Yup.number()
    .typeError("Eso no parece un número")
    .required("Campo requerido"),
};

export const headerListContent = [
  {
    name: "referencia",
    label: "Nro. Referencia",
    type: "sticky",
    listHeader: [
      { name: "dimNumber", label: "Nro. DIM/DEX" },
      {
        name: "canal",
        label: "Canal",
        type: "custom",
        builder: (value) => {
          return (
            <div
              className={`text-white ${
                value === "Canal Rojo"
                  ? "bg-[#fd3838A1]"
                  : value === "Canal Amarillo"
                  ? "bg-[#f3e40c51] text-[#747474]"
                  : value === "Canal Verde"
                  ? "bg-[#4fce4431] text-gray-500"
                  : "text-gray-500"
              } text-center rounded-full py-1 px-2`}
            >
              {value}
            </div>
          );
        },
      },
    ],
  },
  {
    name: "complejidad",
    label: "Complejidad",
    type: "sticky",
    listHeader: [
      {
        name: "prioridad",
        label: "Prioridad",
        type: "custom",
        builder: (value) => {
          return (
            <div
              className={`text-white ${
                value === "Alta"
                  ? "bg-[#fd3838A1]"
                  : value === "Media"
                  ? "bg-[#f3e40c51] text-[#747474]"
                  : value === "Baja"
                  ? "bg-[#4fce4421]"
                  : ""
              } text-center rounded-full w-fit py-1 px-4 `}
            >
              {value}
            </div>
          );
        },
      },
    ],
  },
  { name: "initialDate", label: "Fecha de inicio" },
  {
    name: "proveedor",
    label: "Proveedor",
    type: "sticky",
    listHeader: [{ name: "proveedor", label: "Proveedor" }],
  },
  { name: "stepDetail", label: "Paso actual" },
  { name: "descripcionMercancia", label: "Mercadería" },
  { name: "gestorAsignado", label: "Gestor" },
  { name: "liquidadorAsignado", label: "Liquidador" },
  // { name: "encargadoTramite", label: "Encargado" },
];
export const headerListFiscalizationContent = [
  {
    name: "referencia",
    label: "Nro. Referencia",
    type: "sticky",
    listHeader: [
      { name: "referencia", label: "Nro. Referencia" },
      { name: "dimNumber", label: "Nro. DIM/DEX" },
      {
        name: "canal",
        label: "Canal",
        type: "custom",
        builder: (value) => {
          return (
            <div
              className={`text-white ${
                value === "Canal Rojo"
                  ? "bg-[#fd3838A1]"
                  : value === "Canal Amarillo"
                  ? "bg-[#f3e40c51] text-[#747474]"
                  : value === "Canal Verde"
                  ? "bg-[#4fce4431] text-gray-500"
                  : "text-gray-500"
              } text-center rounded-full py-1 px-2`}
            >
              {value}
            </div>
          );
        },
      },
    ],
  },
  {
    name: "complejidad",
    label: "Complejidad",
    type: "sticky",
    listHeader: [
      {
        name: "prioridad",
        label: "Prioridad",
        type: "custom",
        builder: (value) => {
          return (
            <div
              className={`text-white ${
                value === "Alta"
                  ? "bg-[#fd3838A1]"
                  : value === "Media"
                  ? "bg-[#f3e40c51] text-[#747474]"
                  : value === "Baja"
                  ? "bg-[#4fce4421]"
                  : ""
              } text-center rounded-full w-fit py-1 px-4 `}
            >
              {value}
            </div>
          );
        },
      },
      { name: "complejidad", label: "Complejidad" },
    ],
  },

  {
    name: "tarea",
    label: "Tarea del día",
    type: "sticky",
    listHeader: [
      { name: "tarea", label: "Tarea del día" },
      { name: "estadoDeTarea", label: "Estado de la tarea" },
    ],
  },
  { name: "observacionesDeTarea", label: "Observaciones de la tarea" },
  // { name: "complejidad", label: "Complejidad" },
  { name: "initialDate", label: "Fecha de inicio" },
  {
    name: "proveedor",
    label: "Proveedor",
    type: "sticky",
    listHeader: [
      { name: "proveedor", label: "Proveedor" },
      { name: "facturaComercial", label: "Factura Comercial" },
    ],
  },
  {
    name: "empresaDeTransporte",
    label: "Empresa de Transporte",
    type: "sticky",
    listHeader: [
      { name: "empresaDeTransporte", label: "Empresa de Transporte" },
      { name: "nroEmbarque", label: "Nro. de embarque" },
    ],
  },
  { name: "stepDetail", label: "Paso actual" },
  {
    name: "aduana",
    label: "Aduana",
    type: "sticky",
    listHeader: [
      { name: "aduana", label: "Aduana" },
      {
        name: "regimenPatron",
        label: "Regimen/Patrón",
        type: "custom",
        builder: (value) => {
          return (
            <div
              className={`text-white ${
                value === "IMA4 DESPACHO ANTICIPADO IMP A CONSUMO" ||
                value === "IMI4 DESPACHO INMEDIATO IMP A CONSUMO"
                  ? "text-red-500"
                  : "text-gray-500"
              } text-center rounded-full py-1 px-2`}
            >
              {value}
            </div>
          );
        },
      },
    ],
  },
  { name: "descripcionMercancia", label: "Mercadería" },

  { name: "estadoRecojo", label: "Estado de recojo" },
  { name: "estadoArribo", label: "Estado de arribo" },
  { name: "estadoPago", label: "Estado de pago" },
  { name: "gestorAsignado", label: "Gestor" },
  { name: "liquidadorAsignado", label: "Liquidador" },
  { name: "encargadoTramite", label: "Encargado" },

  {
    name: "almacen",
    label: "Almacén",
    type: "sticky",
    listHeader: [
      { name: "almacen", label: "Almacén" },
      { name: "montoAlmacenaje", label: "Monto de Almacenaje" },
    ],
  },
  // { name: "nextStep", label: "Progreso" },
  { name: "transportista", label: "Transportista" },
  {
    name: "fechaRetiroMercaderia",
    label: "Retiro de Mercadería",
    type: "sticky",
    listHeader: [
      { name: "fechaRetiroMercaderia", label: "Retiro de Mercadería" },
      { name: "fechaEntregaMercaderia", label: "Entrega de Mercadería" },
      {
        name: "fechaEnvioDocumentosCliente",
        label: "Envió de documentos cliente",
      },
    ],
  },
];

export const headerList = [
  // { name: "numberString", label: "Proforma" },
  {
    name: "internCode",
    label: "Nro. interno",
    type: "sticky",
    listHeader: [
      { name: "internCode", label: "Nro. interno" },
      { name: "companyName", label: "Cliente" },
    ],
  },
  ...headerListContent,
];
export const filtersDefaultChart = [
  {
    name: "procedureType",
    url: "/procedureType",
    default: "Todos los tramites",
    valueOption: "id",
    labelOption: "name",
  },
  {
    name: "liquidator",
    url: "/Dropdown/rol_liquidator/options",
    label: "Todos los liquidadores",
    valueOption: "name",
    labelOption: "name",
  },
  {
    name: "gestor",
    url: "/Dropdown/rol_gestor/options",
    label: "Todos los gestores",
    valueOption: "name",
    labelOption: "name",
  },
  // {
  //   name: "inCharge",
  //   url: "/Dropdown/rol_InCharge/options",
  //   label: "Todos los encargados",
  //   valueOption: "id",
  //   labelOption: "name",
  // },
  // {
  //   name: "prioridad",
  //   url: "/Dropdown/prioridad/options",
  //   label: "Prioridad",
  //   valueOption: "name",
  //   labelOption: "name",
  // },
  // {
  //   name: "complejidad",
  //   url: "/Dropdown/ComplejidadDelDespacho/options",
  //   label: "Complejidad",
  //   valueOption: "name",
  //   labelOption: "name",
  // },
  // {
  //   name: "dateQuery",
  //   label: "Filtrar fecha por: ",
  //   options: [
  //     {
  //       label: "Fecha de facturación",
  //       name: "fechaDeFacturación",
  //     },
  //     {
  //       label: "Fecha aceptación",
  //       name: "a2FechaDeAceptación",
  //     },
  //   ],
  // },
  {
    name: "Sucursal",
    label: "Todos las sucursales",
    options: [
      {
        label: "La Paz",
        name: "LP",
      },
      {
        label: "Santa Cruz",
        name: "SC",
      },
    ],
  },
];
export const filtersDefault = [
  {
    name: "companyId",
    url: "/company/getallcompanies",
    label: "Todas las compañías",
    valueOption: "id",
    labelOption: "razonSocial",
  },
  {
    name: "liquidator",
    url: "/Dropdown/rol_liquidator/options",
    label: "Todos los liquidadores",
    valueOption: "name",
    labelOption: "name",
  },
  {
    name: "gestor",
    url: "/Dropdown/rol_gestor/options",
    label: "Todos los gestores",
    valueOption: "name",
    labelOption: "name",
  },
  {
    name: "inCharge",
    url: "/Dropdown/rol_InCharge/options",
    label: "Todos los encargados",
    valueOption: "id",
    labelOption: "name",
  },
  // {
  //   name: "status",
  //   label: "Todos los tramites",
  //   options: [
  //     {
  //       label: "Pendientes",
  //       name: "pendiente",
  //       // initial: true,
  //     },
  //     {
  //       label: "Transmitido",
  //       name: "transmitido",
  //     },
  //     {
  //       label: "Retirados",
  //       name: "retirado",
  //     },
  //   ],
  // },
  // {
  //   name: "statusProforma",
  //   label: "Estado de proforma",
  //   options: [
  //     {
  //       label: "Pendientes",
  //       name: "pendiente",
  //     },
  //     {
  //       label: "Realizados",
  //       name: "realizado",
  //     },
  //   ],
  // },
  // {
  //   name: "step",
  //   url: `/processstep/GetProcessStepsByProcedureTypeId/1`,
  //   label: "Todos los pasos",
  //   valueOption: "step",
  //   labelOption: "name",
  // },
  // {
  //   name: "dateQuery",
  //   label: "Filtrar fecha por:",
  //   // defaultValue: "fechaDeFacturación",
  //   options: [
  //     {
  //       label: "Fecha de facturación",
  //       name: "fechaDeFacturación",
  //     },
  //     {
  //       label: "Fecha aceptación",
  //       name: "a2FechaDeAceptación",
  //     },
  //   ],
  // },
  // { type: "rangeDate" },
];

export const addFiltersDefault = [
  {
    name: "internCode",
    label: "Nro. interno",
    filter: true,
  },
  { name: "dimNumber", label: "Nro. DIM/DEX", filter: true },
  // { name: "referencia", label: "Nro. Referencia", filter: true },
  // {
  //   name: "aduana",
  //   label: "Aduana",
  //   filter: true,
  // },
  {
    name: "canal",
    label: "Canal",
    filter: true,
  },
  // {
  //   name: "complejidad",
  //   label: "Complejidad",
  //   filter: true,
  // },
  // {
  //   name: "regimen",
  //   label: "Regimen",
  //   filter: true,
  // },
  // {
  //   name: "descripcionMercancia",
  //   label: "Mercadería",
  //   filter: true,
  // },
  // {
  //   name: "tipoDeMercadería",
  //   label: "Tipo de Mercadería",
  //   filter: true,
  // },
  // {
  //   name: "prioridad",
  //   label: "Prioridad",
  //   filter: true,
  // },
  // {
  //   name: "transportista",
  //   label: "Transportista",
  //   filter: true,
  // },
  // {
  //   name: "nroEmbarque",
  //   label: "Nro. de embarque",
  //   filter: true,
  // },
  // {
  //   name: "proveedor",
  //   label: "Proveedor",
  //   filter: true,
  // },
  // {
  //   name: "tarea",
  //   label: "Tarea del día",
  //   filter: true,
  // },
];

export const dataGeneral = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "fa-regular fa-rectangle-list",
    // type: 'table',
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "45",
        name: "campo2",
        type: "text",
      },
      {
        id: 3,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo3",
        type: "text",
      },
      {
        id: 4,
        label: "Example4",
        initial: "4544",
        name: "campo4",
        type: "text",
      },
      {
        id: 5,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo5",
        type: "text",
      },
      {
        id: 6,
        label: "Example",
        initial: "45",
        name: "campo6",
        type: "text",
      },
      {
        id: 7,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo7",
        type: "text",
      },
      {
        id: 8,
        label: "Example4",
        initial: "4544",
        name: "campo8",
        type: "text",
      },
      {
        id: 9,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo9",
        type: "text",
      },
      {
        id: 10,
        label: "Example",
        initial: "45",
        name: "campo10",
        type: "text",
      },
      {
        id: 11,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo11",
        type: "text",
      },
      {
        id: 12,
        label: "Example4",
        initial: "4544",
        name: "campo12",
        type: "text",
      },
      {
        id: 13,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo13",
        type: "text",
      },
      {
        id: 14,
        label: "Example4",
        initial: "4544",
        name: "campo14",
        type: "text",
      },
    ],
  },
  {
    id: 2,
    head: "Operadores",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 Aduana",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    head: "Lugares",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 Destino",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
  {
    id: 4,
    head: "Transporte",
    icon: "",
    type: "list",
    items: [
      {
        id: 1,
        label: "A3 Transporte",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "45",
        name: "campo2",
        type: "text",
      },
      {
        id: 3,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo3",
        type: "text",
      },
      {
        id: 4,
        label: "Example4",
        initial: "4544",
        name: "campo4",
        type: "text",
      },
    ],
  },
];
export const dataGeneral2 = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
];
export const dataGeneral3 = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
];

export const monthList = [
  {
    id: 1,
    label: "enero",
    color: "rgb(58,182,229)",
  },
  {
    id: 2,
    label: "febrero",
    color: "rgb(53,184,193)",
  },
  {
    id: 3,
    label: "marzo",
    color: "rgb(205,222,151)",
  },
  {
    id: 4,
    label: "abril",
    color: "rgb(180,212,104)",
  },
  {
    id: 5,
    label: "mayo",
    color: "rgb(128,186,53)",
  },
  {
    id: 6,
    label: "junio",
    color: "rgb(254,223,0)",
  },
  {
    id: 7,
    label: "julio",
    color: "rgb(233,78,22)",
  },
  {
    id: 8,
    label: "agosto",
    color: "rgb(230,39,35)",
  },
  {
    id: 9,
    label: "septiembre",
    color: "rgb(248,201,76)",
  },
  {
    id: 10,
    label: "octubre",
    color: "rgb(248,161,81)",
  },
  {
    id: 11,
    label: "noviembre",
    color: "rgb(225,123,46)",
  },
  {
    id: 12,
    label: "diciembre",
    color: "rgb(172,223,251)",
  },
];
